.login_wrapper {
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/images/bg_login.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login_wrapper > .login_box {
    width: 400px;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
}

.login_wrapper > .login_box > form > button {
    margin-top: 10px;
    width: 100%;
}